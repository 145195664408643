import React from 'react';
import { profile } from '../../signals/profile';
import CreatorMenu from './CreatorMenu';
import DefaultMenu from './DefaultMenu';

const Menu = () => {
  return profile.value.creatorId ? <CreatorMenu /> : <DefaultMenu />
};

export default Menu;
