import React, { memo, useEffect, useRef } from 'react';

import { Container } from './styles';
import useDatePicker from './useDatePicker';
import { Spinner } from 'react-bootstrap';

type Props = {
  onSave: (dateString: string, resetToDate: (date: string) => void) => void;
  onCancel?: () => void;
  isVisible: boolean;
  title: string;
  initialDate?: string;
};

const DatePicker: React.FC<Props> = ({
  onSave,
  onCancel,
  isVisible,
  title,
  initialDate,
}) => {
  const elemRefYear = useRef<HTMLDivElement>(null);
  const elemRefMonth = useRef<HTMLDivElement>(null);
  const elemRefDay = useRef<HTMLDivElement>(null);

  const { dateString, elems, config, selectInitialDate, loading } =
    useDatePicker(
      elemRefYear,
      elemRefMonth,
      elemRefDay,
      isVisible,
      initialDate
    );

  const resetToDate = (date: string) => {
    selectInitialDate(elems, config, date);
  };

  useEffect(() => {
    if (isVisible) {
      window.scrollTo(0, window.screen.height);
    }
  }, [isVisible]);

  return (
    <Container>
      <div
        className="date-selector-container"
        style={{
          left: isVisible ? 0 : -window.screen.width,
        }}
      >
        {loading && (
          <div className="spinner">
            <Spinner
              animation="border"
              role="status"
              className="text-warning font-xs mt-2"
            />
          </div>
        )}
        <div
          className="date-selector-header"
          style={{ opacity: loading ? 0 : 1 }}
        >
          <i className="ti-close btn-cancel" onClick={onCancel}></i>
          <h1 id="DatePicker.title" className="title">
            Date of Birth
          </h1>
          <button
            id="DatePicker.btnSave"
            className="btn-save"
            onClick={() => {
              if (dateString) {
                onSave(dateString, resetToDate);
              }
            }}
          >
            Save
          </button>
        </div>
        <div id="DatePicker.dateSelector" className="date-selector">
          <div id="DatePicker.selector" className="selector" />
          <div className="month" id="DatePicker.month" ref={elemRefMonth} />
          <div className="day" id="DatePicker.day" ref={elemRefDay} />
          <div className="year" id="DatePicker.year" ref={elemRefYear} />
        </div>
      </div>
    </Container>
  );
};

export default memo(DatePicker);
