import React, { useEffect, useRef, useState, useCallback, memo } from 'react';

import { Modal } from 'react-bootstrap';

import QuickPinchZoom, { make3dTransformValue } from 'react-quick-pinch-zoom';

type Props = {
  media: Media[];
  show: boolean;
  onHide: () => void;
  startImageIndex?: number;
  onRemoveImage?: (index: number) => void;
};

const ImageViewer: React.FC<Props> = ({
  show,
  onHide,
  media,
  startImageIndex = 0,
  onRemoveImage,
}) => {
  const [selectedImageSliderIndex, setSelectedImageSliderIndex] = useState(0);

  const imgRef = useRef(null);

  useEffect(() => {
    setSelectedImageSliderIndex(startImageIndex);
  }, [startImageIndex]);

  const onBackPhoto = useCallback(() => {
    setSelectedImageSliderIndex(
      !selectedImageSliderIndex
        ? media.length - 1
        : selectedImageSliderIndex - 1
    );
  }, [media.length, selectedImageSliderIndex]);

  const onNextPhoto = useCallback(() => {
    setSelectedImageSliderIndex((selectedImageSliderIndex + 1) % media.length);
  }, [media.length, selectedImageSliderIndex]);

  const onUpdate = useCallback(({ x, y, scale }) => {
    const { current: img } = imgRef;
    // check if image exists
    if (img) {
      const value = make3dTransformValue({ x, y, scale });
      img.style.setProperty('transform', value);
    }
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'ArrowLeft') {
        onBackPhoto();
      } else if (event.key === 'ArrowRight') {
        onNextPhoto();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [onBackPhoto, onNextPhoto]);

  const onRemove = useCallback(() => {
    setSelectedImageSliderIndex(
      !selectedImageSliderIndex
        ? selectedImageSliderIndex
        : selectedImageSliderIndex - 1
    );
    onRemoveImage(selectedImageSliderIndex);
  }, [onRemoveImage, selectedImageSliderIndex]);

  if (!media[selectedImageSliderIndex]) return null;

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header
        style={{
          border: 0,
          borderRadius: 0,
          display: 'flex',
          justifyContent: 'center',
          padding: 0,
        }}
      >
        <p
          className="text-white fw-600 font-xsss"
          style={{ textAlign: 'center', alignSelf: 'center', margin: 0 }}
        >{`${selectedImageSliderIndex + 1} of ${media.length}`}</p>
        <i
          onClick={onHide}
          className="font-xl text-white feather-x"
          style={{
            cursor: 'pointer',
            right: 0,
            position: 'absolute',
            paddingBottom: 5
          }}
        />
      </Modal.Header>
      <Modal.Body
        style={{
          backgroundColor: 'transparent',
          maxHeight: window.innerHeight - 50,
          display: 'flex',
          justifyContent: 'center',
          padding: 0,
        }}
      >
        <div className="d-flex flex-column justify-content-center">
          {media.length > 1 && (
            <i
              onClick={onBackPhoto}
              className="font-xl text-grey-500 feather-chevron-left align-self-center"
              style={{
                cursor: 'pointer',
                position: 'absolute',
                zIndex: 1,
                left: 0,
                opacity: 0.7,
                backgroundColor: 'white',
                borderRadius: 100,
                marginLeft: 2
              }}
            />
          )}
          <QuickPinchZoom
            onUpdate={onUpdate}
            tapZoomFactor={1}
            zoomOutFactor={1}
            inertiaFriction={0}
            maxZoom={10}
            minZoom={1}
          >
            <img
              ref={imgRef}
              src={media[selectedImageSliderIndex].url}
              alt="img preview"
              style={{
                objectFit: 'contain',
                height: '100%',
                width: '100%',
                borderRadius: 10,
              }}
            />
          </QuickPinchZoom>
          {onRemoveImage ? (
            <i
              onClick={onRemove}
              className="font-md text-white feather-trash-2"
              style={{
                cursor: 'pointer',
                marginBottom: 30,
                position: 'absolute',
                zIndex: 1,
                bottom: 0,
                backgroundColor: '#f92e5a',
                borderRadius: 100,
                padding: 10,
                opacity: 0.8,
                alignSelf: 'center',
              }}
            />
          ) : null}
          {media.length > 1 && (
            <i
              onClick={onNextPhoto}
              className="font-xl text-grey-500 feather-chevron-right align-self-center"
              style={{
                cursor: 'pointer',
                position: 'absolute',
                zIndex: 1,
                right: 0,
                opacity: 0.7,
                backgroundColor: 'white',
                borderRadius: 100,
                marginRight: 2,
              }}
            />
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default memo(ImageViewer);
