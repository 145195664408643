import React, { useRef, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Spinner } from 'react-bootstrap';

import { format, isFuture, parseISO } from 'date-fns';

import Header from '../components/Header';
import Appfooter from '../components/Appfooter';

import LeftMenu from '../components/LeftMenu';
import DatePicker from '../components/DatePicker';
import DateInput from '../components/DateInput';
import { isMinor } from '../utils/data';

// import InputMask from 'react-input-mask';

// import DatePicker from '@date-picker-web-component/component-lib/package';

const validationSchema = Yup.object().shape({
  fullname: Yup.string().required('Required'),
  about: Yup.string().required('Required'),
  birthdate: Yup.string().required('Required'),
});

type FormValues = {
  about: string;
  fullname: string;
  birthdate: string;
};

const Creator = () => {
  const scrollableDivRef = useRef(null);

  const [loading, setLoading] = useState(false);

  const [isDatePickerVisible, setIsDatePickerVisible] = useState(false);

  const identityDocumentInputFile = useRef(null);
  const selfIdentityDocumentInputFile = useRef(null);

  const [identityDocument, setIdentityDocument] =
    useState<UploadMedia | null>();
  const [selfIdentityDocument, setSelfIdentityDocument] =
    useState<UploadMedia | null>();

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldError,
    setFieldValue,
    isValid,
    values,
    touched,
    errors,
  } = useFormik({
    initialValues: { about: '', fullname: '', birthdate: '' },
    validationSchema,
    onSubmit,
    validateOnBlur: true,
  });

  function onSubmit(values: FormValues) {
    try {
      // if (!isValidDate(new Date(values.birthdate))) {
      //   setFieldError('birthdate', 'Invalid Date');
      //   return;
      // }

      // const parsedDate = parse(values.birthdate, 'dd/MM/yyyy', new Date());
      // console.log(parsedDate)

      // const formattedDate = format(parsedDate.toISOString(), 'yyyy-MM-dd');

      setLoading(true);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const onIdentityDocumentFileSelected = (event) => {
    const file = event.target.files[0];

    const identityDocument: UploadMedia = {
      id: '',
      type: 'IMAGE',
      url: URL.createObjectURL(file),
      file: file,
      uploading: false,
      uploaded: false,
      failed: false,
      uploadUrl: '',
    };

    setIdentityDocument(identityDocument);
  };

  const onSelfIdentityDocumentFileSelected = (event) => {
    const file = event.target.files[0];

    const selfIdentityDocument: UploadMedia = {
      id: '',
      type: 'IMAGE',
      url: URL.createObjectURL(file),
      file: file,
      uploading: false,
      uploaded: false,
      failed: false,
      uploadUrl: '',
    };

    setSelfIdentityDocument(selfIdentityDocument);
  };

  return (
    <>
      <Header />
      <LeftMenu />
      <div className="main-content right-chat-active" ref={scrollableDivRef}>
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="middle-wrap">
              <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                <div className="card-body p-4 w-100 border-0 d-flex rounded-3">
                  <h4 className="font-xs text-black fw-600 ms-4 mb-0 mt-2">
                    Become a creator
                  </h4>
                </div>
                <div className="card-body p-lg-5 p-4 w-100 border-0 ">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2">
                            About you
                          </label>
                          <textarea
                            name="about"
                            className="h100 bor-0 w-100 rounded-xxl p-2 font-xss text-grey-600 fw-500 border-light-md theme-dark-bg"
                            value={values.about}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Give us a brief description about yourself"
                            cols={30}
                            rows={10}
                          />
                          <span className="text-danger font-xsss fw-500">
                            {touched.about && errors.about ? errors.about : ''}
                          </span>
                        </div>
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2 mt-2">
                            Full name
                          </label>
                          <input
                            type="text"
                            className="form-control font-xss"
                            disabled={loading}
                            value={values.fullname}
                            placeholder="Full Name"
                            name="fullname"
                            id="fullname"
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          <span className="text-danger font-xsss fw-500">
                            {touched.fullname && errors.fullname
                              ? errors.fullname
                              : ''}
                          </span>
                        </div>
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xss mb-2 mt-2">
                            Date of birth
                          </label>
                          <DateInput
                            id="birthdate"
                            onPress={() => {
                              setIsDatePickerVisible(!isDatePickerVisible);
                              if (scrollableDivRef.current) {
                                scrollableDivRef.current.scrollTop =
                                  scrollableDivRef.current.scrollHeight;
                              }
                            }}
                            value={
                              values.birthdate
                                ? format(
                                    parseISO(values.birthdate),
                                    'MM/dd/yyyy'
                                  )
                                : ''
                            }
                          />
                          <span className="text-danger font-xsss fw-500">
                            {errors.birthdate ? errors.birthdate : ''}
                          </span>
                        </div>
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2 mt-2">
                            Attach a photo of your identity card
                          </label>
                          <button
                            onClick={() => {
                              identityDocumentInputFile.current.click();
                            }}
                            className="d-flex align-items-center font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4 border-0 bg-warning p-2"
                            style={{
                              // cursor: 'pointer',
                              opacity: 1,
                            }}
                          >
                            <i className="font-md text-white feather-paperclip me-2"></i>
                            <span className="text-white">Add file</span>
                          </button>
                          {identityDocument && (
                            <div
                              key={identityDocument.url}
                              className="position-relative"
                            >
                              <img
                                onClick={() => {
                                  // setImagePreviewModal(true);
                                  // setSelectedImageIndex(index);
                                }}
                                src={identityDocument.url}
                                alt="img preview"
                                style={{
                                  maxWidth: '100px',
                                  maxHeight: '100px',
                                  margin: '5px',
                                  cursor: 'pointer',
                                  borderRadius: 10,
                                  opacity:
                                    identityDocument.uploading ||
                                    identityDocument.failed
                                      ? 0.2
                                      : 1,
                                }}
                              />
                              {identityDocument.failed && (
                                <>
                                  <i
                                    className="font-md text-warning feather-alert-circle me-2"
                                    style={{
                                      cursor: 'pointer',
                                      position: 'absolute',
                                      top: 0,
                                      right: -10,
                                      zIndex: 1,
                                    }}
                                  />
                                  <i
                                    onClick={() => false}
                                    className="font-xl text-warning feather-refresh-ccw me-2"
                                    style={{
                                      cursor: 'pointer',
                                      position: 'absolute',
                                      top: '50%',
                                      left: '50%',
                                      transform: 'translate(-50%, -50%)',
                                      zIndex: 1,
                                    }}
                                  />
                                </>
                              )}
                              {identityDocument.uploading ? (
                                <Spinner
                                  animation="border"
                                  role="status"
                                  size="sm"
                                  className="text-warning mt-2"
                                />
                              ) : (
                                <i
                                  onClick={() => setIdentityDocument(null)}
                                  className="font-md text-warning feather-trash-2 me-2"
                                  style={{ cursor: 'pointer' }}
                                />
                              )}
                            </div>
                          )}
                          <input
                            type="file"
                            id="file"
                            ref={identityDocumentInputFile}
                            multiple
                            onChange={onIdentityDocumentFileSelected}
                            style={{ display: 'none' }}
                            accept="image/*"
                          />
                        </div>
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2 mt-2">
                            Attach a self photo with your identity card
                          </label>
                          <button
                            onClick={() => {
                              selfIdentityDocumentInputFile.current.click();
                            }}
                            className="d-flex align-items-center font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4 border-0 bg-warning p-2"
                            style={{
                              // cursor: 'pointer',
                              opacity: 1,
                            }}
                          >
                            <i className="font-md text-white feather-paperclip me-2"></i>
                            <span className="text-white">Add file</span>
                          </button>
                          {selfIdentityDocument && (
                            <div
                              key={selfIdentityDocument.url}
                              className="position-relative"
                            >
                              <img
                                onClick={() => {
                                  // setImagePreviewModal(true);
                                  // setSelectedImageIndex(index);
                                }}
                                src={selfIdentityDocument.url}
                                alt="img preview"
                                style={{
                                  maxWidth: '100px',
                                  maxHeight: '100px',
                                  margin: '5px',
                                  cursor: 'pointer',
                                  borderRadius: 10,
                                  opacity:
                                    selfIdentityDocument.uploading ||
                                    selfIdentityDocument.failed
                                      ? 0.2
                                      : 1,
                                }}
                              />
                              {selfIdentityDocument.failed && (
                                <>
                                  <i
                                    className="font-md text-warning feather-alert-circle me-2"
                                    style={{
                                      cursor: 'pointer',
                                      position: 'absolute',
                                      top: 0,
                                      right: -10,
                                      zIndex: 1,
                                    }}
                                  />
                                  <i
                                    onClick={() => false}
                                    className="font-xl text-warning feather-refresh-ccw me-2"
                                    style={{
                                      cursor: 'pointer',
                                      position: 'absolute',
                                      top: '50%',
                                      left: '50%',
                                      transform: 'translate(-50%, -50%)',
                                      zIndex: 1,
                                    }}
                                  />
                                </>
                              )}
                              {selfIdentityDocument.uploading ? (
                                <Spinner
                                  animation="border"
                                  role="status"
                                  size="sm"
                                  className="text-warning mt-2"
                                />
                              ) : (
                                <i
                                  onClick={() => setSelfIdentityDocument(null)}
                                  className="font-md text-warning feather-trash-2 me-2"
                                  style={{ cursor: 'pointer' }}
                                />
                              )}
                            </div>
                          )}
                          <input
                            type="file"
                            id="file"
                            ref={selfIdentityDocumentInputFile}
                            multiple
                            onChange={onSelfIdentityDocumentFileSelected}
                            style={{ display: 'none' }}
                            accept="image/*"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mt-3 border-0 bg-transparent">
                      <p className="font-xsss mt-1">
                        By becoming a creator, I agree with the{' '}
                        <span
                          className="text-warning fw-700"
                          style={{
                            cursor: 'pointer',
                            textDecoration: 'underline',
                          }}
                          onClick={() => {
                            window
                              .open(
                                'https://pages.silksecret.me/docs/termos',
                                '_blank'
                              )
                              .focus();
                          }}
                        >
                          Terms of Use
                        </span>{' '}
                        and{' '}
                        <span
                          className="text-warning fw-700"
                          onClick={() => {
                            window
                              .open(
                                'https://pages.silksecret.me/docs/privacidade',
                                '_blank'
                              )
                              .focus();
                          }}
                          style={{
                            cursor: 'pointer',
                            textDecoration: 'underline',
                          }}
                        >
                          Privacy Notice
                        </span>
                        , and I'm at least 18 years old.
                      </p>
                    </div>
                    <div className="row mt-2">
                      <div className="col-lg-12">
                        {loading ? (
                          <Spinner
                            animation="border"
                            role="status"
                            className="text-warning mt-3"
                          />
                        ) : (
                          <button
                            onClick={() => handleSubmit()}
                            disabled={
                              !isValid ||
                              !values.about ||
                              !values.fullname ||
                              !values.birthdate
                            }
                            className="bg-warning border-0 text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block"
                            style={
                              !isValid ||
                              !values.about ||
                              !values.fullname ||
                              !values.birthdate
                                ? { opacity: 0.5 }
                                : { opacity: 1 }
                            }
                          >
                            Become a creator
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                  <DatePicker
                    initialDate={values.birthdate}
                    onSave={(
                      date: string,
                      resetToDate: (date: string) => void
                    ) => {
                      setIsDatePickerVisible(false);

                      if (isFuture(date)) {
                        setFieldError(
                          'birthdate',
                          'Select a valid date of birth'
                        );
                        return;
                      }

                      if (isMinor(date)) {
                        setFieldError(
                          'birthdate',
                          'You must be at least 18 years old'
                        );
                        return;
                      }
                      setFieldValue('birthdate', date);

                      // resetToDate(values.birthdate);
                    }}
                    onCancel={() => setIsDatePickerVisible(false)}
                    isVisible={isDatePickerVisible}
                    title="Date of Birth"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Appfooter />
    </>
  );
};

export default Creator;
