import React, { useState } from 'react';

import ProfilePhotoUploadModal from '../modals/ProfilePhotoModal';
import MyProfileBanner from './MyProfileBanner';
import { profile } from '../signals/profile';
import { session } from '../signals/session';
import { Link } from 'react-router-dom';

const MyProfileCard = () => {
  const [showProfileImageUpload, setShowProfileImageUpload] = useState(false);

  return (
    <div className="card w-100 border-0 p-0 bg-white shadow-xss rounded-xxl">
      <MyProfileBanner />
      <div className="card-body p-0 position-relative">
        <figure
          className="avatar position-absolute w100 z-index-1"
          style={{ top: '-40px', left: '30px', cursor: 'pointer' }}
          onClick={() => {
            setShowProfileImageUpload(true);
          }}
        >
          <img
            src={
              !profile.value.avatar
                ? 'assets/images/user-100x100.png'
                : profile.value.avatar
            }
            alt="avater"
            className="float-right p-1 bg-white rounded-circle w-100"
          />
        </figure>
        <div className="d-flex flex-row w-100 justify-content-between">
          <div className="d-flex flex-column">
            <h4 className="fw-700 font-sm mt-2 pl-15">
              {session.value.username}
            </h4>
            <h4 className="fw-500 font-xssse mb-5 pl-15">{`@${session.value.username}`}</h4>
          </div>
          <Link
            to="/settings"
            className="nav-content-btn open-font h-auto pt-2 pb-2"
          >
            <i
              className="font-xl feather-settings me-3 text-warning mt-2"
              style={{
                cursor: 'pointer',
              }}
            />
          </Link>
        </div>
      </div>

      <div className="card-body d-block w-100 shadow-none mb-0 p-0 border-top-xs flex-row d-flex p-2 align-items-center">
        <img
          src="assets/images/instagram-logo.png"
          style={{
            maxHeight: 35,
            maxWidth: 35,
            cursor: 'pointer',
            marginRight: 10,
          }}
          alt=""
          onClick={() =>
            window.open('https://www.instagram.com/', '_blank').focus()
          }
        />
        <img
          src="assets/images/x-logo.png"
          style={{
            maxHeight: 25,
            maxWidth: 25,
            cursor: 'pointer',
            marginRight: 10,
          }}
          alt=""
          onClick={() => window.open('https://x.com/', '_blank').focus()}
        />
        <img
          src="assets/images/tiktok.png"
          style={{
            maxHeight: 25,
            maxWidth: 25,
            cursor: 'pointer',
            marginRight: 8,
          }}
          alt=""
          onClick={() => window.open('https://tiktok.com/', '_blank').focus()}
        />
        {/* <img
          src="assets/images/site-logo.png"
          style={{
            maxHeight: 35,
            maxWidth: 35,
            cursor: 'pointer',
          }}
          alt=""
          onClick={() => false}
        /> */}
      </div>
      <ProfilePhotoUploadModal
        isVisible={showProfileImageUpload}
        onClose={() => {
          setShowProfileImageUpload(false);
        }}
      />
    </div>
  );
};

export default MyProfileCard;
