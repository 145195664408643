import axios, { AxiosResponse } from 'axios';
import { session } from '../signals/session';

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

export const signIn = (email: string, password: string) => {
  return new Promise<{ token: string; refreshToken: string }>(
    async (resolve, reject) => {
      try {
        const resp = await axios.post(`${REACT_APP_API_URL}/login`, {
          email,
          password,
        });
        resolve({
          token: resp.data.accessToken,
          refreshToken: resp.data.refreshToken,
        });
      } catch (error) {
        reject(error);
      }
    }
  );
};

export const verifyEmail = (token: string) => {
  return new Promise<AxiosResponse>(async (resolve, reject) => {
    try {
      const resp = await axios.get(`${REACT_APP_API_URL}/verify-email`, {
        params: { token },
      });
      resolve(resp);
    } catch (error) {
      reject(error);
    }
  });
};

export const resetPassword = (password: string, token: string) => {
  return new Promise<AxiosResponse>(async (resolve, reject) => {
    try {
      const resp = await axios.post(`${REACT_APP_API_URL}/password/reset`, {
        password,
        token,
      });
      resolve(resp);
    } catch (error) {
      reject(error);
    }
  });
};

export const requestForgotPassword = (email: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      const resp = await axios.post(
        `${REACT_APP_API_URL}/password/request-reset`,
        {
          email,
        }
      );
      resolve(resp);
    } catch (error) {
      reject(error);
    }
  });
};

export const requestNewToken = () => {
  return new Promise<{ accessToken: string; refreshToken: string }>(
    async (resolve, reject) => {
      try {
        const { refreshToken } = session.value;

        const resp = await axios.get(`${REACT_APP_API_URL}/refresh`, {
          headers: {
            Authorization: `Bearer ${refreshToken}`,
          },
        });
        resolve({
          accessToken: resp.data.accessToken,
          refreshToken: resp.data.refreshToken,
        });
      } catch (error) {
        reject(error);
      }
    }
  );
};

export const verifyTurnstile = (token: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      const resp = await axios.post(
        `${REACT_APP_API_URL}/cf/verify-turnstile`,
        {
          token,
        }
      );
      resolve(resp);
    } catch (error) {
      reject(error);
    }
  });
};
