import React, { useRef, useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import AvatarEditor from 'react-avatar-editor';
import { getImageUploadUrl, uploadMedia } from '../../services/post';
import { updateAvatar } from '../../services/user';

type Props = {
  onSaveNewProfilePhoto: (photo: any) => void;
  onClose: () => void;
  newProfilePhotoToEdit: any;
};

const Editor: React.FC<Props> = ({
  onClose,
  newProfilePhotoToEdit,
  onSaveNewProfilePhoto,
}) => {
  const editorRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const onSave = async () => {
    if (editorRef.current) {
      try {
        setLoading(true);

        const canvas = editorRef.current.getImage().toDataURL();
        const canvasResp = await fetch(canvas);
        const blob = await canvasResp.blob();

        const imageURL = window.URL.createObjectURL(blob);

        const mediaUploadUrl = await getImageUploadUrl();
        const uploadMediaResponse = await uploadMedia(mediaUploadUrl, blob);

        await updateAvatar(uploadMediaResponse.id);

        onSaveNewProfilePhoto(imageURL);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Modal.Header
        closeButton
        onClick={onClose}
        className="bg-white"
        style={{
          border: 0,
          borderRadius: 0,
          padding: 20,
          borderTopLeftRadius: 10,
          WebkitBorderTopRightRadius: 10,
        }}
      >
        <h1 className="fw-700 text-grey-900 font-md">Edit photo</h1>
      </Modal.Header>
      <Modal.Body className="bg-white d-flex justify-content-center align-items-center flex-column">
        {loading ? (
          <Spinner animation="border" role="status" className="text-warning" />
        ) : (
          <AvatarEditor
            ref={editorRef}
            image={newProfilePhotoToEdit}
            border={50}
            scale={1.2}
            borderRadius={1000}
          />
        )}
      </Modal.Body>
      <Modal.Footer className="bg-white">
        <button
          onClick={onSave}
          disabled={loading}
          className="d-flex w-100 align-items-center justify-content-center font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4 border-0 bg-transparent"
          style={{ cursor: 'pointer' }}
        >
          <i className="font-md text-warning feather-save me-2"></i>
          <span>Save Photo</span>
        </button>
      </Modal.Footer>
    </>
  );
};

export default Editor;
