import React from 'react';

type Props = {
  onPress: () => void;
  value: string;
  id: string;
};

const DateInput: React.FC<Props> = ({ onPress, value, id }) => {
  return (
    <div
      className="form-group mb-2 d-flex flex-row align-items-center"
      style={{ position: 'relative' }}
      onClick={onPress}
    >
      <input
        type="text"
        className="style2-input form-control text-grey-900 font-xss"
        placeholder="MM/DD/YYYY"
        id={id}
        readOnly
        value={value}
        style={{
          cursor: 'pointer',
          backgroundColor: 'white',
        }}
      />
      <i
        className={`font-sm feather-chevron-right text-grey-500 pe-0`}
        style={{
          position: 'absolute',
          right: 10,
          cursor: 'pointer',
        }}
        onClick={() => {
          console.log();
        }}
      />
    </div>
  );
};

export default DateInput;
