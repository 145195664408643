import React, { useRef } from 'react';

import { Modal } from 'react-bootstrap';
import { profile } from '../../signals/profile';

type Props = {
  onAddNewPhoto: (photo: any) => void;
  onClose: () => void;
};

const Preview: React.FC<Props> = ({ onClose, onAddNewPhoto }) => {
  const inputFile = useRef(null);

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const imageUrl = URL.createObjectURL(file);
      onAddNewPhoto(imageUrl);
    }
  };

  return (
    <>
      <Modal.Header
        closeButton
        onClick={onClose}
        className="bg-white"
        style={{
          border: 0,
          borderRadius: 0,
          padding: 20,
          borderTopLeftRadius: 10,
          WebkitBorderTopRightRadius: 10,
        }}
      >
        <h1 className="fw-700 text-grey-900 font-md">Profile photo</h1>
      </Modal.Header>
      <Modal.Body className="bg-white d-flex justify-content-center align-items-center flex-column">
        <figure className="avatar">
          <img
            src={
              !profile.value.avatar ? 'assets/images/user-400x400.png' : profile.value.avatar
            }
            style={{
              width: 300,
              height: 300,
            }}
            alt="avatar"
            className="float-right p-1 bg-white rounded-circle w-100"
          />
        </figure>
      </Modal.Body>
      <Modal.Footer className="bg-white">
        <div
          onClick={() => {
            inputFile.current.click();
          }}
          className="d-flex w-100 align-items-center justify-content-center font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4"
          style={{ cursor: 'pointer' }}
        >
          <i className="font-md text-warning feather-image me-2"></i>
          <span>{!profile.value.avatar ? 'Add Photo' : 'Change Photo'}</span>
        </div>
        <input
          type="file"
          id="file"
          ref={inputFile}
          multiple
          onChange={handleImageChange}
          style={{ display: 'none' }}
          accept="image/*"
        />
      </Modal.Footer>
    </>
  );
};

export default Preview;
