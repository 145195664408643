import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const menu: {
  section: {
    title: string;
    id: string;
    items: { id: string; icon: string; title: string; route: string }[];
  };
}[] = [
  {
    section: {
      id: 'general',
      title: '',
      items: [
        { id: 'feed', icon: 'feather-unlock', title: 'Feed', route: '/' },
        {
          id: 'following',
          icon: 'feather-user-check',
          title: 'Following',
          route: '/following',
        },
        {
          id: 'subscriptions',
          icon: 'feather-bookmark',
          title: 'Subscriptions',
          route: '/subscriptions',
        },
      ],
    },
  },
  {
    section: {
      id: 'account',
      title: 'Account',
      items: [
        {
          id: 'myprofile',
          icon: 'feather-user',
          title: 'My profile',
          route: '/myprofile',
        },
        {
          id: 'creator',
          icon: 'feather-plus-square',
          title: 'Become a creator',
          route: '/creator',
        },
        {
          id: 'settings',
          icon: 'feather-settings',
          title: 'Settings',
          route: '/settings',
        },
      ],
    },
  },
  {
    section: {
      id: 'payment',
      title: 'Payment',
      items: [
        {
          id: 'payment',
          icon: 'feather-credit-card',
          title: 'My card',
          route: '/payment',
        },
      ],
    },
  },
];

const LeftDefaultMenu = () => {
  const [selectedItemId, setSelectedItemId] = useState('feed');

  useEffect(() => {
    const pathname = window.location.pathname.replace('/', '');

    setSelectedItemId(pathname ? pathname: 'feed');

  }, []);

  return (
    <div className="navigation scroll-bar mt-3">
      <div className="container ps-0 pe-0">
        <div className="nav-content">
          {menu.map((menuItem) => {
            return (
              <div
                key={menuItem.section.id}
                className="nav-wrap bg-white bg-transparent-card rounded-xxl shadow-xss pt-3 pb-1 mb-2 mt-2"
              >
                <div className="nav-caption fw-600 font-xsss text-grey-500">
                  <span></span> {menuItem.section.title}
                </div>
                <ul className="mb-1 top-content">
                  {menuItem.section.items.map((sectionItem) => {
                    return (
                      <li
                        key={sectionItem.id}
                        id={sectionItem.id}
                      >
                        <Link
                          to={sectionItem.route}
                          className="open-font h-auto pt-2 pb-2"
                          id={sectionItem.id}
                        >
                          <i
                            className={`font-sm ${
                              sectionItem.icon
                            } me-3 ${
                              selectedItemId === sectionItem.id
                                ? 'text-warning'
                                : 'text-grey-500'
                            }`}
                          ></i>
                          <h4
                            className={`fw-600 ${
                              selectedItemId === sectionItem.id
                                ? 'text-warning'
                                : 'text-grey-500'
                            } font-xsss mb-0 mt-0`}
                          >
                            {sectionItem.title}
                          </h4>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default LeftDefaultMenu;
