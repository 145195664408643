import { signal } from '@preact/signals';

export type Session = {
  token: string;
  refreshToken: string;
  userId: string;
  username: string;
}

export const session = signal<Session>({
  token: '',
  refreshToken: '',
  userId: '',
  username: '',
});

export const isLoggedOut = signal(false);
