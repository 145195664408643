enum CardTypes {
  visa = 'visa',
  mastercard = 'mastercard',
  discover = 'discover',
  amex = 'amex',
  jcb = 'jcb',
  dinersclub = 'dinersclub',
  maestro = 'maestro',
  laser = 'laser',
  unionpay = 'unionpay',
  elo = 'elo',
  hipercard = 'hipercard',
}

export default CardTypes;
