import React, { useState, memo, useEffect } from 'react';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';

type Props = {
  onChangeText: (text: string) => void;
  placeholder: string;
  initialValue?: string;
};

const TextArea: React.FC<Props> = ({ onChangeText, placeholder, initialValue }) => {
  const [text, setText] = useState('');
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  useEffect(() => {
    if (initialValue) {
      setText(initialValue);
    }
  }, [initialValue]);

  const handleInputChange = (e: any) => {
    const newText =
      e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
    setText(newText);
    onChangeText(newText);
  };

  const handleEmojiSelect = (emoji) => {
    const newText = text + emoji.native + ' ';
    setText(newText);
    onChangeText(newText);
    setShowEmojiPicker(false);
  };

  return (
    <>
      <div className="d-flex flex-row align-items-center">
        <textarea
          name="message"
          className="h100 bor-0 w-100 rounded-xxl p-2 font-xsss text-grey-600 fw-500 border-light-md theme-dark-bg"
          value={text}
          onChange={handleInputChange}
          cols={30}
          rows={10}
          placeholder={placeholder}
          onFocus={() => setShowEmojiPicker(false)}
        />
        <button onClick={() => setShowEmojiPicker(true)} className='border-0 bg-transparent'>
          <i
            className="btn-round-sm font-md fw-600 text-warning ti-face-smile mx-1"
            style={{ cursor: 'pointer' }}
          />
        </button>
        {showEmojiPicker ? (
          <div
            style={{
              position: 'absolute',
              zIndex: 1,
              right: -20,
            }}
          >
            <Picker
              data={data}
              onEmojiSelect={handleEmojiSelect}
              // onClickOutside={() => setShowEmojiPicker(false)}
            />
          </div>
        ) : null}
      </div>
    </>
  );
};

export default memo(TextArea);
