import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import Header from '../components/Header';
import Appfooter from '../components/Appfooter';
import { Modal } from 'react-bootstrap';
import { logout } from '../utils/session';
import LeftMenu from '../components/LeftMenu';
import { profile } from '../signals/profile';

const Settings = () => {
  const [showLogoutModal, setShowLogoutModal] = useState(false);

  console.log(profile.value.creatorId)

  return (
    <>
      <Header />
      <LeftMenu />
      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="middle-wrap">
              <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                <div className="card-body p-lg-5 p-4 w-100 border-0">
                  <div className="row">
                    <div className="col-lg-12">
                      <h4 className="mb-4 font-xs text-black fw-700 mont-font mb-lg-5 mb-4 font-md-xs">
                        Settings
                      </h4>
                      <ul className="list-inline mb-4">
                        {profile.value.creatorId && (
                          <li className="list-inline-item d-block border-bottom me-2">
                            <Link
                              to="/creator-settings"
                              className="pt-2 pb-2 d-flex align-items-center"
                            >
                              <i className="btn-round-md bg-warning text-white feather-settings font-md me-3"></i>{' '}
                              <h4 className="fw-600 font-xsss mb-0 mt-0">
                                Creator Settings
                              </h4>
                            </Link>
                          </li>
                        )}
                        <li className="list-inline-item d-block border-bottom me-2">
                          <Link
                            to="/account-information"
                            className="pt-2 pb-2 d-flex align-items-center"
                          >
                            <i className="btn-round-md bg-warning text-white feather-user font-md me-3"></i>{' '}
                            <h4 className="fw-600 font-xsss mb-0 mt-0">
                              My Account
                            </h4>
                          </Link>
                        </li>
                        <li className="list-inline-item d-block border-bottom me-0">
                          <Link
                            to="/change-password"
                            className="pt-2 pb-2 d-flex align-items-center"
                          >
                            <i className="btn-round-md bg-warning text-white feather-lock font-md me-3"></i>{' '}
                            <h4 className="fw-600 font-xsss mb-0 mt-0">
                              Change Password
                            </h4>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal show={showLogoutModal}>
          <Modal.Header
            closeButton
            onClick={() => setShowLogoutModal(false)}
            className="bg-white"
            style={{
              border: 0,
              borderRadius: 0,
              padding: 20,
              borderTopLeftRadius: 10,
              WebkitBorderTopRightRadius: 10,
            }}
          />
          <Modal.Body className="bg-white">
            <p className="font-xs text-black text-center">
              Do you really want to leave?
            </p>
          </Modal.Body>
          <Modal.Footer className="bg-white d-flex flex-row">
            <div className="form-group mb-1 flex-grow-1">
              <button
                onClick={() => setShowLogoutModal(false)}
                className="form-control text-center style2-input text-white fw-600 bg-dark border-0 p-0"
              >
                Cancel
              </button>
            </div>
            <div className="form-group mb-1 flex-grow-1">
              <button
                onClick={() => {
                  logout();
                }}
                className="form-control text-center style2-input text-white fw-600 bg-warning border-0 p-0"
              >
                Confirm
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
      <Appfooter />
    </>
  );
};

export default Settings;
