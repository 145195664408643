import React, { useCallback, useEffect, useState } from 'react';

import { isValid, parseISO } from 'date-fns';

interface Config {
  itemHeight: number;
  itemAngle: number;
  radius: number;
  quarterCount: number;
}

interface Elem {
  el: HTMLDivElement;
  circleList: HTMLUListElement | null;
  circleItems: NodeListOf<any> | null;
  highlight: HTMLDivElement | null;
  highlightList: HTMLUListElement | null;
  events: {
    touchstart: (e: any) => void;
    touchmove: (e: any) => void;
    touchend: (e: any) => void;
  };
  values: { value: number; text: string }[];
  scroll: number;
  moveT: number;
  selectedValue: number;
}

interface TouchData {
  startY: number;
  yArr: any[];
  touchScroll: number;
}

const scrollSmooth = 50;

const monthIndex = 0;
const dayIndex = 1;
const yearIndex = 2;

const easing = {
  easeOutCubic: function (pos: number) {
    return Math.pow(pos - 1, 3) + 1;
  },
  easeOutQuart: function (pos: number) {
    return -(Math.pow(pos - 1, 4) - 1);
  },
};

const useDatePicker = (
  elemRefYear: React.RefObject<HTMLDivElement>,
  elemRefMonth: React.RefObject<HTMLDivElement>,
  elemRefDay: React.RefObject<HTMLDivElement>,
  isVisible: boolean,
  initialDate?: string
) => {
  const [config, setConfig] = useState<Config>({
    itemAngle: 0,
    itemHeight: 0,
    radius: 0,
    quarterCount: 0,
  });

  const [elems, setElems] = useState<Elem[]>([]);
  const [year, setYear] = useState(1900);
  const [month, setMonth] = useState(1);
  const [day, setDay] = useState(1);
  const [dateString, setDateString] = useState<string>();
  const [loading, setLoading] = useState(false);

  const getMonths = useCallback(() => {
    const months: { value: number; text: string }[] = [
      { value: 1, text: 'Jan' },
      { value: 2, text: 'Feb' },
      { value: 3, text: 'Mar' },
      { value: 4, text: 'Apr' },
      { value: 5, text: 'May' },
      { value: 6, text: 'Jun' },
      { value: 7, text: 'Jul' },
      { value: 8, text: 'Aug' },
      { value: 9, text: 'Sep' },
      { value: 10, text: 'Oct' },
      { value: 11, text: 'Nov' },
      { value: 12, text: 'Dec' },
    ];

    return months;
  }, []);

  const getDays = useCallback((year: number, month: number) => {
    const dayCount = new Date(year, month, 0).getDate();
    const days: { value: number; text: string }[] = [];

    for (let i = 1; i <= dayCount; i++) {
      days.push({
        value: i,
        text: i.toString(),
      });
    }

    return days;
  }, []);

  const getYears = useCallback(() => {
    const currentYear = new Date().getFullYear();
    const years: { value: number; text: string }[] = [];

    for (let i = 1900; i <= currentYear; i++) {
      years.push({
        value: i,
        text: i.toString(),
      });
    }

    return years;
  }, []);

  const stop = (elem: Elem) => {
    cancelAnimationFrame(elem.moveT);
  };

  const normalizeScroll = useCallback((scroll: number, elem: Elem) => {
    let normalizedScroll = scroll;

    while (normalizedScroll < 0) {
      normalizedScroll += elem.values.length;
    }

    normalizedScroll = normalizedScroll % elem.values.length;

    return normalizedScroll;
  }, []);

  const moveTo = useCallback(
    (scroll: number, elem: Elem, config: Config) => {
      scroll = normalizeScroll(scroll, elem);

      if (config && elem.highlightList && elem.circleList && elem.circleItems) {
        const { radius, itemAngle, itemHeight, quarterCount } = config;

        elem.circleList.style.transform = `translate3d(0, 0, ${-radius}px) rotateX(${
          itemAngle * scroll
        }deg)`;
        elem.highlightList.style.transform = `translate3d(0, ${-scroll * itemHeight}px, 0)`;

        elem.el.style.color = 'transparent';

        Array.from(elem.circleItems).forEach((itemElem) => {
          if (Math.abs(itemElem.dataset.index - scroll) > quarterCount) {
            itemElem.style.visibility = 'hidden';
          } else {
            itemElem.style.visibility = 'visible';
          }
        });
      }

      return scroll;
    },
    [normalizeScroll]
  );

  const animateToScroll = useCallback(
    (initScroll: number, finalScroll: number, t: number, elem: Elem, config: Config) => {
      if (initScroll === finalScroll || t === 0) {
        moveTo(initScroll, elem, config);
        return;
      }

      const start = new Date().getTime() / 1000;
      let pass = 0;
      const totalScrollLen = finalScroll - initScroll;

      return new Promise<void>((resolve) => {
        const tick = () => {
          pass = new Date().getTime() / 1000 - start;

          if (pass < t) {
            elem.scroll = moveTo(
              initScroll + easing['easeOutQuart'](pass / t) * totalScrollLen,
              elem,
              config
            );
            elem.moveT = requestAnimationFrame(tick);
          } else {
            stop(elem);
            elem.scroll = moveTo(initScroll + totalScrollLen, elem, config);
            resolve();
          }
        };

        tick();
      });
    },
    [moveTo]
  );

  const selectByScroll = useCallback(
    (paramScroll: number, elem: Elem, config: Config) => {
      paramScroll = normalizeScroll(paramScroll, elem) | 0;

      if (paramScroll > elem.values.length - 1) {
        paramScroll = elem.values.length - 1;
        moveTo(paramScroll, elem, config);
      }

      moveTo(paramScroll, elem, config);

      elem.scroll = paramScroll;

      const selected = elem.values[paramScroll] ? elem.values[paramScroll] : elem.values[0];

      elem.selectedValue = selected.value;

      elem.el.setAttribute('content-desc', selected.value.toString());

      if (elem.el === elemRefYear.current) {
        setYear(selected.value);
      } else if (elem.el === elemRefMonth.current) {
        setMonth(selected.value);
      } else if (elem.el === elemRefDay.current) {
        setDay(selected.value);
      }
    },
    [elemRefDay, elemRefMonth, elemRefYear, moveTo, normalizeScroll]
  );

  const animateMoveByInitV = useCallback(
    async (initV: number, elem: Elem, config: Config) => {
      let a1 = 0;
      let t1 = 0;

      a1 = initV > 0 ? -scrollSmooth : scrollSmooth;
      t1 = Math.abs(initV / a1);

      const totalScrollLen = initV * t1 + (a1 * t1 * t1) / 2;
      const finalScroll = Math.round(elem.scroll + totalScrollLen);

      await animateToScroll(elem.scroll, finalScroll, t1, elem, config);

      selectByScroll(elem.scroll, elem, config);
    },
    [animateToScroll, selectByScroll]
  );

  const touchstart = useCallback((e: MouseEvent & TouchEvent, touchData: TouchData, elem: Elem) => {
    elem.el.addEventListener('touchmove', elem.events.touchmove);
    document.addEventListener('mousemove', elem.events.touchmove);

    const eventY = e.clientY || e.touches[0].clientY;

    touchData.startY = eventY;
    touchData.yArr = [[eventY, new Date().getTime()]];
    touchData.touchScroll = elem.scroll;

    stop(elem);
  }, []);

  const touchend = useCallback(
    (e: MouseEvent, touchData: TouchData, elem: Elem, config: Config) => {
      const { itemHeight } = config;

      elem.el.removeEventListener('touchmove', elem.events.touchmove);
      document.removeEventListener('mousemove', elem.events.touchmove);

      try {
        let v;

        if (touchData.yArr.length === 1) {
          v = 0;
        } else {
          const startTime = touchData.yArr[touchData.yArr.length - 2][1];
          const endTime = touchData.yArr[touchData.yArr.length - 1][1];
          const startY = touchData.yArr[touchData.yArr.length - 2][0];
          const endY = touchData.yArr[touchData.yArr.length - 1][0];

          v = (((startY - endY) / itemHeight) * 1000) / (endTime - startTime);
          const sign = v > 0 ? 1 : -1;

          v = Math.abs(v) > 30 ? 30 * sign : v;
        }

        elem.scroll = touchData.touchScroll;

        animateMoveByInitV(v, elem, config);
      } catch (err) {
        console.log(err);
      }
    },
    [animateMoveByInitV]
  );

  const touchmove = useCallback(
    (e: MouseEvent & TouchEvent, touchData: TouchData, elem: Elem, config: Config) => {
      const { itemHeight } = config;

      const eventY = e.clientY || e.touches[0].clientY;

      touchData.yArr.push([eventY, new Date().getTime()]);

      const scrollAdd = (touchData.startY - eventY) / itemHeight;
      let moveToScroll = scrollAdd + elem.scroll;

      moveToScroll = normalizeScroll(moveToScroll, elem);

      touchData.touchScroll = moveTo(moveToScroll, elem, config);
    },
    [moveTo, normalizeScroll]
  );

  const select = useCallback(
    (elem: Elem, value: number, config: Config) => {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise<void>(async (resolve) => {
        for (let i = 0; i < elem.values.length; i++) {
          if (elem.values[i].value === value) {
            window.cancelAnimationFrame(elem.moveT);
            const initScroll = normalizeScroll(elem.scroll, elem);
            const finalScroll = i;
            const t = Math.sqrt(Math.abs((finalScroll - initScroll) / scrollSmooth));

            await animateToScroll(initScroll, finalScroll, t, elem, config);

            resolve();
            setTimeout(() => {
              selectByScroll(i, elem, config);
            });
            return;
          }
        }
      });
    },
    [animateToScroll, normalizeScroll, selectByScroll]
  );

  const getMouseOrTouchEvent = useCallback(
    (
      event: (e: MouseEvent & TouchEvent, touchData: TouchData, elem: Elem, config: Config) => void,
      elem: Elem,
      touchData: TouchData,
      config: Config
    ) => {
      return (e: MouseEvent & TouchEvent) => {
        if (elem.el.contains(e.target as Node) || e.target === elem.el) {
          e.preventDefault();

          if (elem.values.length) {
            event(e, touchData, elem, config);
          }
        }
      };
    },
    []
  );

  const mountTemplate = useCallback(
    (elem: Elem, config: Config) => {
      const { radius, itemHeight, itemAngle, quarterCount } = config;

      let circleListHTML = '';

      for (let i = 0; i < elem.values.length; i++) {
        circleListHTML += `<li class="select-option"
                  style="
                    top: ${itemHeight * -0.5}px;
                    height: ${itemHeight}px;
                    line-height: ${itemHeight}px;
                    transform: rotateX(${-itemAngle * i}deg) translate3d(0, 0, ${radius}px);
                  "
                  data-index="${i}"
                  >${elem.values[i].text}</li>`;
      }

      let highListHTML = '';

      for (let i = 0; i < elem.values.length; i++) {
        highListHTML += `<li class="highlight-item" style="height: ${itemHeight}px;">
                      ${elem.values[i].text}
                    </li>`;
      }

      for (let i = 0; i < quarterCount; i++) {
        circleListHTML =
          `<li class="select-option"
                    style="
                      top: ${itemHeight * -0.5}px;
                      height: ${itemHeight}px;
                      line-height: ${itemHeight}px;
                      transform: rotateX(${itemAngle * (i + 1)}deg) translate3d(0, 0, ${radius}px);
                    "
                    data-index="${-i - 1}"
                    >${elem.values[elem.values.length - i - 1].text}</li>` + circleListHTML;

        circleListHTML += `<li class="select-option"
                    style="
                      top: ${itemHeight * -0.5}px;
                      height: ${itemHeight}px;
                      line-height: ${itemHeight}px;
                      transform: rotateX(${
                        -itemAngle * (i + elem.values.length)
                      }deg) translate3d(0, 0, ${radius}px);
                    "
                    data-index="${i + elem.values.length}"
                    >${elem.values[i].text}</li>`;
      }

      highListHTML =
        `<li class="highlight-item" style="height: ${itemHeight}px;">
      ${elem.values[elem.values.length - 1].text}
      </li>` + highListHTML;
      highListHTML += `<li class="highlight-item" style="height: ${itemHeight}px;">${elem.values[0].text}</li>`;

      const template = `
        <div id=${elem.el.id} class="select-wrap">
          <ul id=${
            elem.el.id
          } class="select-options" style="transform: translate3d(0, 0, ${-radius}px) rotateX(0deg);">
            ${circleListHTML}
            <!-- <li class="select-option">a0</li> -->
          </ul>
          <div class="highlight">
            <ul class="highlight-list">
              <!-- <li class="highlight-item"></li> -->
              ${highListHTML}
            </ul>
          </div>
        </div>
      `;

      elem.el.innerHTML = template;

      elem.circleList = elem.el.querySelector('.select-options');

      elem.circleItems = elem.el.querySelectorAll('.select-option');

      elem.highlight = elem.el.querySelector('.highlight');
      elem.highlightList = elem.el.querySelector('.highlight-list');

      if (elem.highlight && elem.highlightList && elem.circleList) {
        elem.highlight.style.height = itemHeight + 'px';
        elem.highlight.style.lineHeight = itemHeight + 'px';
      }

      const touchData: TouchData = {
        startY: 0,
        yArr: [],
        touchScroll: 0,
      };

      elem.events['touchend'] = getMouseOrTouchEvent(touchend, elem, touchData, config);
      elem.events['touchstart'] = getMouseOrTouchEvent(touchstart, elem, touchData, config);
      elem.events['touchmove'] = getMouseOrTouchEvent(touchmove, elem, touchData, config);

      elem.el.addEventListener('touchstart', elem.events.touchstart);
      document.addEventListener('mousedown', elem.events.touchstart);
      elem.el.addEventListener('touchend', elem.events.touchend);
      document.addEventListener('mouseup', elem.events.touchend);

      if (elem.values.length) {
        const value = elem.values[0].value;

        for (let i = 0; i < elem.values.length; i++) {
          if (elem.values[i].value === value) {
            window.cancelAnimationFrame(elem.moveT);
            // scroll = moveTo(i, elem);
            const initScroll = normalizeScroll(elem.scroll, elem);
            const finalScroll = i;
            const t = Math.sqrt(Math.abs((finalScroll - initScroll) / scrollSmooth));

            animateToScroll(initScroll, finalScroll, t, elem, config);
            setTimeout(() => selectByScroll(i, elem, config));
            return;
          }
        }
      }
    },
    [
      animateToScroll,
      getMouseOrTouchEvent,
      normalizeScroll,
      selectByScroll,
      touchend,
      touchmove,
      touchstart,
    ]
  );

  const mountElement = (elemRef: HTMLDivElement, values: { value: number; text: string }[]) => {
    const elem: Elem = {
      el: elemRef,
      circleList: null,
      circleItems: null,
      highlight: null,
      highlightList: null,
      events: {
        touchend: () => {
          return false;
        },
        touchmove: () => {
          return false;
        },
        touchstart: () => {
          return false;
        },
      },
      values,
      scroll: 0,
      moveT: 0,
      selectedValue: 0,
    };

    return elem;
  };

  const selectInitialDate = useCallback(
    async (elems: Elem[], config: Config, initialDate?: string) => {
      const elemMonth = elems[monthIndex];
      const elemDay = elems[dayIndex];
      const elemYear = elems[yearIndex];

      const today = new Date();

      let month = today.getMonth() + 1; // January is 0!
      let day = today.getDate() > elemDay.values.length ? elemDay.values.length : today.getDate();
      let year = today.getFullYear() - 50; // Start 50 years ago

      if (initialDate) {
        const parsedDate = parseISO(initialDate);

        month = parsedDate.getMonth() + 1;
        day = parsedDate.getDate();
        year = parsedDate.getFullYear();
      }

      setLoading(true);

      await select(elemMonth, month, config);
      await select(elemDay, day, config);
      await select(elemYear, year, config);

      setLoading(false);
    },
    [select]
  );

  const createConfig = useCallback((elems: Elem[]) => {
    const count = 20 - (20 % 4);
    const quarterCount = count / 4;
    const itemHeight = (elems[0].el.offsetHeight * 3) / count;
    const itemAngle = 360 / count;
    const radius = itemHeight / Math.tan((itemAngle * Math.PI) / 180);

    return { itemHeight, itemAngle, radius, quarterCount, elems };
  }, []);

  useEffect(() => {
    if (elemRefYear.current && elemRefMonth.current && elemRefDay.current) {
      const years = getYears();
      const months = getMonths();

      const today = new Date();
      const year = initialDate ? parseISO(initialDate).getFullYear() : today.getFullYear() - 50;
      const month = initialDate ? parseISO(initialDate).getMonth() + 1 : today.getMonth() + 1;

      const days = getDays(year, month);

      const elemYear = mountElement(elemRefYear.current, years);
      const elemMonth = mountElement(elemRefMonth.current, months);
      const elemDay = mountElement(elemRefDay.current, days);

      const elems = [elemMonth, elemDay, elemYear];

      const config = createConfig(elems);

      for (const elem of elems) {
        mountTemplate(elem, config);
      }

      selectInitialDate(elems, config, initialDate);

      setElems(elems);
      setConfig(config);
    }
  }, [
    elemRefYear,
    elemRefMonth,
    elemRefDay,
    initialDate,
    mountTemplate,
    selectInitialDate,
    createConfig,
    getYears,
    getMonths,
    getDays,
  ]);

  useEffect(() => {
    if (elems.length && isVisible) {
      const days = getDays(year, month);

      elems[dayIndex].values = days;

      mountTemplate(elems[dayIndex], config);

      select(elems[dayIndex], day > days.length ? days.length : day, config);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elems, month, year, mountTemplate, select, config]);

  useEffect(() => {
    if (year && month && day) {
      const date = `${year}-${('0' + month).slice(-2)}-${('0' + day).slice(-2)}`;

      const parsedDate = parseISO(date);

      const isValidDate = isValid(parsedDate);

      setDateString(isValidDate ? date : '');
    }
  }, [year, month, day]);

  return { dateString, elems, config, selectInitialDate, loading };
};

export default useDatePicker;
