import React from 'react';

import { Modal } from 'react-bootstrap';

type Props = {
  isOpen: boolean;
  message: string;
  onClose: () => void;
};

const ErrorModal: React.FC<Props> = ({ isOpen, message, onClose }) => {
  return (
    <Modal show={isOpen} onHide={onClose}>
      <Modal.Header
        closeButton
        onClick={onClose}
        className="bg-white"
        style={{
          border: 0,
          borderRadius: 0,
          padding: 20,
          borderTopLeftRadius: 10,
          WebkitBorderTopRightRadius: 10,
        }}
      />
      <Modal.Body
        className="bg-white"
        style={{
          borderBottomLeftRadius: 10,
          borderBottomRightRadius: 10,
        }}
      >
        <p className="font-xs text-black">{message}</p>
        <div className="form-group mb-1">
          <button
            onClick={onClose}
            className="form-control text-center style2-input text-white fw-600 bg-warning border-0 p-0"
          >
            OK
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ErrorModal;
