import React, { useState } from 'react';
import ImageViewer from './ImageViewer';
import { useNavigate } from 'react-router-dom';
import { formatLikes, formatPostDate } from '../utils/data';
import { session } from '../signals/session';

import { Modal } from 'react-bootstrap';

import { Stream } from '@cloudflare/stream-react';

type Props = {
  feedPostIndex: number;
  feedPost: Post;
  profile?: Profile;
  onLikePost?: (postIndex: number) => void;
  onDeletePost?: (postIndex: number) => void;
};

const PostView: React.FC<Props> = ({
  feedPostIndex,
  feedPost,
  profile,
  onLikePost,
  onDeletePost,
}) => {
  const navigate = useNavigate();

  const [showImages, setShowImages] = useState(false);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);

  const username = profile ? profile.username : feedPost.username;
  const avatar = profile ? profile.avatar : feedPost.avatar;

  return (
    <div className="card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3">
      <div className="card-body p-0 d-flex justify-content-between">
        <div className="d-flex">
          <figure
            className="avatar me-3"
            style={{
              cursor: 'pointer',
            }}
            onClick={() =>
              username !== session.value.username
                ? navigate(`/${username}`)
                : false
            }
          >
            <img
              src={avatar ? avatar : 'assets/images/user.png'}
              alt="avatar"
              className="shadow-sm rounded-circle w45"
            />
          </figure>
          <h4
            className="fw-700 text-grey-900 font-xssss mt-1"
            style={{
              cursor: 'pointer',
            }}
            onClick={() =>
              username !== session.value.username
                ? navigate(`/${username}`)
                : false
            }
          >
            {username}
            <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
              {formatPostDate(feedPost.createdAt)}
            </span>
          </h4>
        </div>
        {onDeletePost && (
          <div
            className={`ms-auto pointer ${isMenuOpen ? 'show' : 'hide'}`}
            id="dropdownMenu4"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <i className="ti-more-alt text-grey-900 btn-round-md bg-greylight font-xss"></i>
          </div>
        )}
        <div
          className={`dropdown-menu p-4 right-0 rounded-xxl border-0 shadow-lg ${
            isMenuOpen ? 'show' : 'hide'
          }`}
          aria-labelledby="dropdownMenu4"
        >
          <div
            className="card-body p-0 d-flex"
            onClick={() => {
              setIsDeleteConfirmationOpen(true);
              setIsMenuOpen(false);
            }}
          >
            <i className="feather-trash text-grey-500 me-3 font-lg"></i>
            <h4 className="fw-600 text-grey-900 font-xssss mt-0 me-4 pointer">
              Delete Post{' '}
              <span className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500">
                Remove this post
              </span>
            </h4>
          </div>
        </div>
      </div>
      <p className="fw-500 lh-24 font-xsss mb-0">{feedPost.content}</p>
      {feedPost.medias.length > 0 && feedPost.medias[0].type === 'VIDEO' ? (
        <div className="card-body p-0 mb-3 rounded-3 overflow-hidden uttam-die">
          <Stream
            controls
            src={feedPost.medias[0].url}
          />
        </div>
      ) : (
        <>
          {feedPost.medias.length > 0 && (
            <div
              className="card-body d-block p-0 mb-3"
              style={{
                cursor: 'pointer',
              }}
              onClick={() => {
                setShowImages(true);
              }}
            >
              <div className="row ps-2 pe-2">
                <div className="col-sm-12 p-1 position-relative">
                  <img
                    src={feedPost.medias[0].url}
                    className="rounded-3 w-100"
                    style={{
                      objectFit: 'cover',
                      aspectRatio: 1 / 1,
                    }}
                    alt="post"
                  />
                  {feedPost.medias.length > 1 ? (
                    <div
                      style={{
                        width: 150,
                        height: 150,
                        backgroundColor: 'black',
                        position: 'absolute',
                        zIndex: 1,
                        bottom: 5,
                        right: 5,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderTopLeftRadius: 5,
                        opacity: 0.7,
                      }}
                    >
                      <span className="text-white font-xxl">{`+ ${
                        feedPost.medias.length - 1
                      }`}</span>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {/* {postvideo ? (
        <div className="card-body p-0 mb-3 rounded-3 overflow-hidden uttam-die">
          <a href="/defaultvideo" className="video-btn">
            <video autoPlay loop className="float-right w-100">
              <source src={`assets/images/${postvideo}`} type="video/mp4" />
            </video>
          </a>
        </div>
      ) : (
        ''
      )} */}
      {/* <div className="card-body p-0 me-lg-5">
                <p className="fw-500 text-grey-500 lh-26 font-xssss w-100 mb-2">{des} <a href="/defaultvideo" className="fw-600 text-primary ms-2">See more</a></p>
            </div> */}
      {/* {postimage ? ( */}
      {/* {feedPost.medias.length > 0 && (
        <div
          className="card-body d-block p-0 mb-3"
          style={{
            cursor: 'pointer',
          }}
          onClick={() => {
            setShowImages(true);
          }}
        >
          <div className="row ps-2 pe-2">
            <div className="col-sm-12 p-1 position-relative">
              <img
                src={feedPost.medias[0].url}
                className="rounded-3 w-100"
                style={{
                  objectFit: 'cover',
                  aspectRatio: 1 / 1,
                }}
                alt="post"
              />
              {feedPost.medias.length > 1 ? (
                <div
                  style={{
                    width: 150,
                    height: 150,
                    backgroundColor: 'black',
                    position: 'absolute',
                    zIndex: 1,
                    bottom: 5,
                    right: 5,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderTopLeftRadius: 5,
                    opacity: 0.7,
                  }}
                >
                  <span className="text-white font-xxl">{`+ ${
                    feedPost.medias.length - 1
                  }`}</span>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      )} */}
      <div className="d-flex flex-row">
        {onLikePost ? (
          <i
            className={`feather-heart font-md ${
              feedPost.liked ? 'text-danger fw-800' : 'text-grey-500'
            }`}
            style={{ cursor: 'pointer', marginRight: 5 }}
            onClick={() => onLikePost(feedPostIndex)}
          />
        ) : null}
        <span className="font-xssss fw-600">{formatLikes(feedPost.likes)}</span>
      </div>
      {/* <div className="d-flex justify-content-center flex-column align-items-center">
        <button
          onClick={() => false}
          className="btn p-2 m-2 rounded-md bg-warning font-xssss fw-700 text-white"
        >
          Subscribe for only $8,90/month
          <i
            className={`feather-lock font-xsss text-white fw-800`}
            style={{ cursor: 'pointer', marginLeft: 5 }}
          />
        </button>
      </div> */}
      <ImageViewer
        onHide={() => setShowImages(false)}
        media={feedPost.medias}
        show={showImages}
      />
      <Modal
        show={isDeleteConfirmationOpen}
        onHide={() => setIsDeleteConfirmationOpen(false)}
      >
        <Modal.Header
          closeButton
          onClick={() => setIsDeleteConfirmationOpen(false)}
          className="bg-white"
          style={{
            border: 0,
            borderRadius: 0,
            padding: 20,
            borderTopLeftRadius: 10,
            WebkitBorderTopRightRadius: 10,
          }}
        />
        <Modal.Body
          className="bg-white"
          style={{
            borderBottomLeftRadius: 10,
            borderBottomRightRadius: 10,
          }}
        >
          <p className="font-xs text-black">
            {'Do you really want to delete this post?'}
          </p>
          <div className="form-group mb-1">
            <button
              onClick={() => {
                onDeletePost(feedPostIndex);
                setIsDeleteConfirmationOpen(false);
              }}
              className="form-control text-center style2-input text-white fw-600 bg-warning border-0 p-0"
            >
              Confirm
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PostView;
