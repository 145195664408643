import styled, { css } from 'styled-components';

// type ContainerProps = {
//   textActive?: string;
//   textTitle?: string;
//   backgroundColor?: string;
//   neutral50?: string;
//   text100?: string;
//   fontSizeXL?: string;
//   fontSizeBodyBaseDefault?: string;
// };

export const Container = styled.div`
  ${(props) => css`
    .select-wrap {
      position: relative;
      top: 10%;
      height: 60%;
      text-align: center;
      overflow: hidden;
      font-size: 20px;
      color: #ddd;

      &:before,
      &:after {
        position: absolute;
        z-index: 1;
        display: block;
        content: '';
        width: 100%;
        height: 50%;
      }
      &:before {
        top: 0;
      }
      &:after {
        bottom: 0;
      }

      .select-options {
        position: absolute;
        top: 50%;
        list-style-type: none;
        text-align: center;
        width: 100%;
        height: 0;
        transform-style: preserve-3d;
        margin: 0 auto;
        display: block;
        transform: translateZ(-150px) rotateX(0deg);
        -webkit-font-smoothing: subpixel-antialiased;
        color: gray;
        font-weight: 600;

        .select-option {
          position: absolute;
          top: 0;
          height: 50px;

          -webkit-font-smoothing: subpixel-antialiased;
          @for $i from 1 through 100 {
            &:nth-child(#{$i}) {
              transform: rotateX(-18deg * ($i - 1)) translateZ(150px);
            }
          }
        }
      }
    }

    .selector {
      position: absolute;
      background-color: transparent;
      border: 1px solid white;
      box-shadow: 0px 0px 10px rgb(0 0 0 / 0.5);
      border-radius: 8px;
      height: 45px;
      width: 100%;
      /* margin-left: 35px; */
      display: inline;
      top: 40%;
      transform: translate(0, -50%);
      z-index: 1;
      pointer-events: none;
    }

    .highlight {
      position: relative;
      top: 50%;
      transform: translate(0, -50%);
      background-color: #f7f7f7;
      color: black;
      overflow: hidden;
    }

    .highlight-list {
      position: absolute;
      list-style-type: none;
      top: -100%;
      font-size: 18px;
    }

    .date-selector-container {
      position: absolute;
      z-index: 1;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      width: 100%;
      height: 50%;
      display: flex;
      flex-direction: column;
      background-color: #f9f9f9;
      bottom: 0px;
    }

    .date-selector-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-left: 10px;
      padding-right: 10px;
      margin-top: 10px;

      .btn-save {
        background-color: transparent;
        border: 0;
        color: #f92e5a;
        font-size: 16px;
        font-weight: 600;
      }

      .btn-cancel {
        background-color: transparent;
        border: 0;
        color: black;
        font-size: 18px;
        padding-top: 5px;
        cursor: pointer;
      }

      .title {
        color: '#000';
        font-size: 18px;
        font-weight: 600;
        padding-left: 10px;
        padding-top: 5px;
      }
    }

    .date-selector {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex: 1;

      .select-wrap {
        font-size: 18px;
      }
      .highlight {
        font-size: 18px;
        font-weight: 600 !important;
      }
    }

    .month {
      display: flex;
      flex-direction: column;
      flex: 1;

      .select-option {
        /* right: -5px; */
        /* flex: 1;
        display: flex; */
        font-size: 16px;
        width: 100%;
        /* width: 100%; */
      }

      .highlight-list {
        text-align: center;
        width: 100%;
        /* background-color: red; */
        /* left: 60px; */
      }
    }

    .day {
      display: flex;
      flex-direction: column;
      flex: 1;

      .select-option {
        font-size: 16px;
        text-align: center;
        width: 100%;
      }

      .highlight-list {
        width: 100%;
        /* background-color: blue; */
      }
    }

    .year {
      display: flex;
      flex-direction: column;
      flex: 1;

      .select-option {
        font-size: 16px;
        width: 100%;
      }

      .highlight-list {
        width: 100%;
        /* background-color: yellow; */
      }
    }

    .spinner {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      background-color: #f7f7f7;
      z-index: 2;
      border-top-right-radius: 20px;
      border-top-left-radius: 20px;
    }

    /* @media all and (min-width: 200px) and (max-width: 300px) {
      .selector {
        width: 88%;
      }

      .month {
        .select-option {
          right: 0;
        }

        .highlight-list {
          right: 0;
        }
      }

      .selector {
        margin-left: 15px;
      }
    } */

    /* @media all and (min-width: 300px) and (max-width: 320px) {
      .selector {
        width: 90%;
      }

      .month {
        .select-option {
          right: 0;
        }

        .highlight-list {
          right: 0;
        }
      }

      .selector {
        margin-left: 15px;
      }
    } */

    @media all and (min-width: 500px) {
      .date-selector {
        width: 30em;
        align-self: center;
      }
      .selector {
        width: 90%;
      }

      .month {
        .select-option {
          /* right: -20px; */
        }

        .highlight-list {
          /* right: 18px; */
        }
      }

      /* .selector {
        margin-left: 5px;
      } */
    }
  `}
`;
