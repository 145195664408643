import React from 'react';
import { useNavigate } from 'react-router-dom';

import Header from '../components/Header';
import Appfooter from '../components/Appfooter';

const PayoutAccount = () => {
  const navigate = useNavigate();

  return (
    <>
      <Header />
      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="middle-wrap">
              <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                <div className="card-body p-4 w-100 border-0 d-flex rounded-3">
                  <button
                    onClick={() => navigate(-1)}
                    className="d-inline-block mt-2 bg-transparent border-0"
                  >
                    <i className="ti-arrow-left font-sm text-black"></i>
                  </button>
                  <h4 className="font-xs text-black fw-600 ms-4 mb-0 mt-2">
                    Payout Account
                  </h4>
                </div>
                <div className="card-body p-lg-5 w-100 border-0 "></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Appfooter />
    </>
  );
};

export default PayoutAccount;
