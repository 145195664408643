import React from 'react';

import { profile } from '../../signals/profile';

import LeftCreatorMenu from './LeftCreatorMenu';
import LeftDefaultMenu from './LeftDefaultMenu';

const LeftMenu = () => {
  return profile.value.creatorId ? <LeftCreatorMenu /> : <LeftDefaultMenu /> 
}

export default LeftMenu;
