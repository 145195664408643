import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import Header from '../components/Header';
import Appfooter from '../components/Appfooter';
import { Spinner } from 'react-bootstrap';
import LeftMenu from '../components/LeftMenu';

import InputMask from 'react-input-mask';
import Select from 'react-select';

import { countries, isMinor } from '../utils/data';
import DatePicker from '../components/DatePicker';
import DateInput from '../components/DateInput';
import { format, isFuture, parseISO } from 'date-fns';

const validationSchema = Yup.object().shape({
  fullname: Yup.string().required('Required'),
  birthdate: Yup.string(),
  cpf: Yup.string(),
  country: Yup.string(),
  cep: Yup.string(),
  address: Yup.string(),
  number: Yup.string(),
  complement: Yup.string(),
  city: Yup.string(),
  state: Yup.string(),
});

type FormValues = {
  email: string;
  fullname: string;
  birthdate: string;
  cpf: string;
  country: string;
  cep: string;
  address: string;
  number: string;
  complement: string;
  city: string;
  state: string;
};

const Account = () => {
  const scrollableDivRef = useRef(null);

  const [loading, setLoading] = useState(false);

  const [isDatePickerVisible, setIsDatePickerVisible] = useState(false);

  const navigate = useNavigate();

  const onSubmit = async (values: FormValues) => {
    try {
      setLoading(true);
      // await updateFullname(values.fullname);
      // profile.value.fullname = values.fullname;
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const { handleSubmit, handleChange, setFieldValue, setFieldError, values } =
    useFormik({
      initialValues: {
        email: '',
        fullname: '',
        birthdate: '',
        cpf: '',
        country: '',
        cep: '',
        address: '',
        number: '',
        complement: '',
        city: '',
        state: '',
      },
      validationSchema,
      onSubmit,
      validateOnBlur: false,
    });

  return (
    <>
      <Header />
      <LeftMenu />
      <div className="main-content right-chat-active" ref={scrollableDivRef}>
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="middle-wrap">
              <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                <div className="card-body p-4 w-100 border-0 d-flex rounded-3">
                  <button
                    onClick={() => navigate(-1)}
                    className="d-inline-block mt-2 bg-transparent border-0"
                  >
                    <i className="ti-arrow-left font-sm text-black"></i>
                  </button>
                  <h4 className="font-xs text-black fw-600 ms-4 mb-0 mt-2">
                    My Account
                  </h4>
                </div>
                <div className="card-body p-lg-5 p-4 w-100 border-0 ">
                  <form>
                    <div className="row">
                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2">
                            Country
                          </label>
                          <Select
                            placeholder={'Select your country'}
                            onChange={(country) => {
                              setFieldValue('country', country.value);
                            }}
                            value={{
                              label: values.country,
                              value: values.country,
                            }}
                            options={countries}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2">
                            Full name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            disabled={loading}
                            value={values.fullname}
                            id="fullname"
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2">
                            E-mail
                          </label>
                          <input
                            disabled
                            type="text"
                            className="form-control"
                            value={values.email}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2">
                            Date of birth
                          </label>
                          <DateInput
                            id="birthdate"
                            onPress={() => {
                              setIsDatePickerVisible(!isDatePickerVisible);
                              if (scrollableDivRef.current) {
                                scrollableDivRef.current.scrollTop =
                                  scrollableDivRef.current.scrollHeight;
                              }
                            }}
                            value={
                              values.birthdate
                                ? format(
                                    parseISO(values.birthdate),
                                    'MM/dd/yyyy'
                                  )
                                : ''
                            }
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2">
                            CPF
                          </label>
                          <InputMask
                            className="form-control"
                            mask="999.999.999-99"
                            placeholder="000.000.000-00"
                            value={values.cpf}
                            name="cpf"
                            id="cpf"
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2">
                            CEP
                          </label>
                          <InputMask
                            className="form-control"
                            mask="99.999.999"
                            placeholder="00.000.000"
                            value={values.cep}
                            name="cep"
                            id="cep"
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2">
                            Address
                          </label>
                          <input
                            className="form-control"
                            value={values.address}
                            name="address"
                            id="address"
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2">
                            Number
                          </label>
                          <input
                            className="form-control"
                            value={values.number}
                            name="number"
                            id="number"
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2">
                            Complement
                          </label>
                          <input
                            className="form-control"
                            value={values.complement}
                            name="complement"
                            id="complement"
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2">
                            City
                          </label>
                          <input
                            className="form-control"
                            value={values.city}
                            name="city"
                            id="city"
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2">
                            State
                          </label>
                          <input
                            className="form-control"
                            value={values.state}
                            name="state"
                            id="state"
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        {loading ? (
                          <Spinner
                            animation="border"
                            role="status"
                            className="text-warning mt-3"
                          />
                        ) : (
                          <button
                            onClick={() => handleSubmit()}
                            className="bg-warning border-0 text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block"
                          >
                            Save
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                  <DatePicker
                    initialDate={values.birthdate}
                    onSave={(
                      date: string,
                      resetToDate: (date: string) => void
                    ) => {
                      setIsDatePickerVisible(false);

                      if (isFuture(date)) {
                        setFieldError(
                          'birthdate',
                          'Select a valid date of birth'
                        );
                        return;
                      }

                      if (isMinor(date)) {
                        setFieldError(
                          'birthdate',
                          'You must be at least 18 years old'
                        );
                        return;
                      }
                      setFieldValue('birthdate', date);
                    }}
                    onCancel={() => setIsDatePickerVisible(false)}
                    isVisible={isDatePickerVisible}
                    title="Date of Birth"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Appfooter />
    </>
  );
};

export default Account;
