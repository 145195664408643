import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import Appfooter from '../components/Appfooter';
import ProfileDetail from '../components/ProfileDetail';
import ProfileCard from '../components/ProfileCard';
import { Spinner } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { session } from '../signals/session';
import FeedScrollPage from '../components/Utility/FeedScrollPage';
import Feed from '../components/Utility/Feed';
import LeftMenu from '../components/LeftMenu';
import useFeed from '../hooks/useFeed';

const UserPage = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const {
    feed,
    isFeedLoading,
    isFeedNextPageLoading,
    loadFeed,
    onLikePost,
    fetchFeedNextPage,
  } = useFeed();

  const [isMembership, setIsMembership] = useState(true);

  useEffect(() => {
    const username = location.pathname.replace('/', '');
    if (username === session.value.username) {
      navigate('/myprofile');
      return;
    }
    loadFeed(username);
  }, [loadFeed, location.pathname, navigate]);

  const onReachOutBottom = () => {
    const username = location.pathname.replace('/', '');
    fetchFeedNextPage(username);
  };

  return (
    <>
      <Header />
      <LeftMenu />
      {isFeedLoading ? (
        <div
          className="align-self-center d-flex flex-column justify-content-center align-items-center"
          style={{
            marginTop: 150,
          }}
        >
          <Spinner animation="border" role="status" className="text-warning" />
        </div>
      ) : (
        <FeedScrollPage
          loading={isFeedLoading}
          footerLoading={isFeedNextPageLoading}
          onReachOutBottom={onReachOutBottom}
          feed={feed}
        >
          <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left pe-0">
              <div className="row">
                <div className="col-xl-12 mb-3">
                  <ProfileCard
                    profile={feed.profile}
                    creatorId={feed.creatorId}
                  />
                </div>
                <div className="col-xl-4 col-xxl-3 col-lg-4 pe-0">
                  <ProfileDetail profile={feed.profile} />
                </div>
                <div className="col-xl-8 col-xxl-9 col-lg-8">
                  {isMembership ? (
                    <>
                      <div className="card w-100 shadow-xss rounded-xxl border-0 d-flx flex-row justify-content-between align-items-center p-3 mb-3">
                        <div className="d-flex flex-column justify-content-center align-items-center">
                          <i className="font-sm feather-lock mb-2 text-grey-500"></i>
                          <h4 className="fw-600 text-grey-600 font-xsss mb-0 mt-0">
                            119 posts
                          </h4>
                        </div>
                        <div className="d-flex flex-column justify-content-center align-items-center">
                          <i className="font-sm feather-image mb-2 text-grey-500"></i>
                          <h4 className="fw-600 text-grey-600 font-xsss mb-0 mt-0">
                            103 photos
                          </h4>
                        </div>
                        <div className="d-flex flex-column justify-content-center align-items-center">
                          <i className="font-sm feather-film mb-2 text-grey-500"></i>
                          <h4 className="fw-600 text-grey-600 font-xsss mb-0 mt-0">
                            19 videos
                          </h4>
                        </div>
                      </div>
                      <Feed
                        isNextPageLoading={isFeedNextPageLoading}
                        feed={feed}
                        onLikePost={onLikePost}
                      />
                    </>
                  ) : (
                    <div className="d-flex justify-content-center mt-5 flex-column align-items-center">
                      <i className="feather-lock text-warning display3-size mb-2"></i>
                      <button
                        onClick={() => setIsMembership(true)}
                        className="btn p-2 m-2 rounded-md bg-warning font-xssss fw-700 text-white"
                      >
                        Subscribe for only $8,90/month
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </FeedScrollPage>
      )}
      <Appfooter />
    </>
  );
};

export default UserPage;
