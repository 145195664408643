import jwtDecode from 'jwt-decode';
import { requestNewToken } from '../services/auth';
import { session } from '../signals/session';
import {
  clearCookie,
  getCookie,
  getObject,
  setCookie,
  setObject,
} from './storage';

type Session = {
  token: string;
  refreshToken: string;
  userId: string;
  username: string;
  expires: number;
};

export const persistAuthSession = (session: Session): void => {
  try {
    setCookie('session', session, session.expires);
  } catch (err) {
    console.log(err);
  }
};

export const getAuthSession = (): Session | null => {
  try {
    const authSession = getCookie<Session>('session');
    return authSession;
  } catch (e) {
    return null;
  }
};

export const scheduleRefreshToken = () => {
  let refreshTokenInterval = getObject<number>('refreshTokenInterval');
  if (refreshTokenInterval) {
    window.clearInterval(refreshTokenInterval);
  }
  refreshTokenInterval = window.setInterval(async () => {
    try {
      const { accessToken, refreshToken } = await requestNewToken();

      const { exp } = jwtDecode<JWT>(accessToken);

      persistAuthSession({
        token: accessToken,
        refreshToken: refreshToken,
        userId: session.value.userId,
        username: session.value.username,
        expires: exp,
      });

      session.value.token = accessToken;
    } catch (error) {
      console.log(error);
    }
  }, 1800000); //30min
  setObject('refreshTokenInterval', refreshTokenInterval);
};

export const stopRefreshToken = () => {
  let refreshTokenInterval = getObject<number>('refreshTokenInterval');
  if (refreshTokenInterval) {
    window.clearInterval(refreshTokenInterval);
  }
};

export const logout = () => {
  clearCookie('session');
  stopRefreshToken();
  session.value = {
    token: '',
    refreshToken: '',
    userId: '',
    username: '',
  };
  window.location.reload();
};
