import React from 'react';
import PostView from '../PostView';
import Load from '../Load';

type Props = {
  feed: Feed;
  onLikePost?: (postIndex: number) => void;
  onDeletePost?: (postIndex: number) => void;
  isLoading?: boolean;
  isNextPageLoading?: boolean;
};

const Feed: React.FC<Props> = ({
  feed,
  onLikePost,
  onDeletePost,
  isLoading,
  isNextPageLoading,
}) => {
  if (isLoading) {
    return <Load />;
  }

  if (feed.posts.length) {
    return (
      <>
        {feed.posts.map((feedPost, index) => {
          return (
            <div key={feedPost.postId}>
              <PostView
                feedPost={feedPost}
                profile={feed.profile}
                onLikePost={onLikePost}
                onDeletePost={onDeletePost}
                feedPostIndex={index}
              />
            </div>
          );
        })}
        {isNextPageLoading && <Load />}
      </>
    );
  }

  return (
    <div
      className="align-self-center d-flex flex-column justify-content-center align-items-center"
      style={{
        marginTop: 50,
      }}
    >
      <i className="feather-book display5-size text-grey-400"></i>
      <span className="text-grey-500 m-2 font-md">No posts yet</span>
    </div>
  );
};

export default Feed;
