import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Header from '../components/Header';
import Appfooter from '../components/Appfooter';
import { formatCurrency } from '../utils/data';
import LeftMenu from '../components/LeftMenu';

const Payout = () => {
  // const [loading, setLoading] = useState(false);
  const [history] = useState<
    {
      date: string;
      amount: number;
      status: boolean;
    }[]
  >([
    {
      date: '2024-11-02',
      amount: 20.0,
      status: true,
    },
  ]);

  const navigate = useNavigate();

  return (
    <>
      <Header />
      <LeftMenu />

      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="middle-wrap">
              <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                <div className="card-body p-4 w-100 border-0 d-flex rounded-3">
                  <h4 className="font-xs text-black fw-600 ms-4 mb-0 mt-2">
                    Payout
                  </h4>
                </div>
                <div className="card-body p-lg-5 w-100 border-0 ">
                  <div className="d-flex flex-row justify-content-between">
                    <div>
                      <h5 className="font-xsss text-grey-600 fw-500 mt-2">
                        Balance
                      </h5>
                      <p className="font-xl">$ 0.00</p>
                    </div>
                    <div className="d-flex flex-column justify-content-center">
                      <button
                        onClick={() => {
                          console.log('false');
                        }}
                        className="rounded-3 bg-current mb-2 mt-5 p-3 fw-600 fw-700 text-center font-xssss mont-font text-uppercase ls-3 text-white d-block border-0 bg-warning"
                      >
                        withdraw
                      </button>
                      <button
                        className="font-xsss text-warning fw-500 text-decoration-underline border-0 bg-transparent"
                        style={{
                          cursor: 'pointer',
                        }}
                        onClick={() => navigate('/payout-account')}
                      >
                        configure account
                      </button>
                    </div>
                  </div>
                  <h5 className="font-xss text-black fw-600 mt-5 mb-3">
                    Recent history
                  </h5>
                  <div className="d-flex flex-column">
                    <div className="d-flex flex-row justify-content-between">
                      <span className="font-xss fw-600 text-left">Date</span>
                      <span className="font-xss fw-600 text-center">
                        Amount
                      </span>
                      <span className="font-xss fw-600 text-right">Status</span>
                    </div>
                    <div>
                      {history.map((item) => {
                        return (
                          <div className="d-flex flex-row justify-content-between">
                            <p className="font-xsss text-left">{item.date}</p>
                            <p className="font-xsss text-center me-4">
                              {formatCurrency(item.amount)}
                            </p>
                            <p className="font-xsss text-right">
                              {item.status ? 'Success' : 'Failure'}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Appfooter />
    </>
  );
};

export default Payout;
