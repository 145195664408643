import React, { ReactElement, useCallback, useEffect, useRef } from 'react';
import { scrolling } from '../../signals/feedScrollPage';

type Props = {
  loading: boolean;
  footerLoading: boolean;
  children?: ReactElement;
  feed: Feed;
  onReachOutBottom: () => void;
};

let isScrolling;

const FeedScrollPage: React.FC<Props> = ({
  children,
  loading,
  footerLoading,
  feed,
  onReachOutBottom,
}) => {
  const containerRef = useRef(null);

  useEffect(() => {
    return () => {
      scrolling.value = false;
    }
  }, [])

  const handleScroll = useCallback(() => {
    const listContainer = containerRef.current;

    scrolling.value = listContainer.scrollTop > 0;

    // Clear our timeout throughout the scroll
    window.clearTimeout(isScrolling);

    // Set a timeout to run after scrolling ends
    isScrolling = setTimeout(function () {
      // Run the callback
      scrolling.value = false;
    }, 500);

    if (
      listContainer.scrollTop + listContainer.clientHeight >=
      listContainer.scrollHeight
    ) {
      if (
        !loading &&
        !footerLoading &&
        feed.posts.length &&
        feed.page <= feed.totalPages
      ) {
        onReachOutBottom();
      }
    }
  }, [
    feed.page,
    feed.posts.length,
    feed.totalPages,
    footerLoading,
    loading,
    onReachOutBottom,
  ]);

  useEffect(() => {
    const listContainer = containerRef.current;
    if (listContainer) {
      listContainer.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (listContainer) {
        listContainer.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll]);

  return (
    <div
      className="main-content"
      ref={containerRef}
      style={{ overflowY: 'scroll', height: '900px', paddingBottom: 80 }}
    >
      {children}
    </div>
  );
};

export default FeedScrollPage;
