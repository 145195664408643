import React, { useState, useCallback, useEffect } from 'react';

const userData = [
  'mantega',
  'murillo',
  'murillomassarotto',
  'peroco',
  'rafael',
  'rafaelperoco',
];

const SearchBar = () => {
  const [isSearchResultOpen, setIsSearchResultOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const [searchResult, setSearchResult] = useState<string[]>([]);

  const onSearch = useCallback((query: string) => {
    setIsSearchResultOpen(true);
    setTimeout(() => {
      console.log('Search results for:', query);
      if (query.length >= 4) {
        const result = userData.filter((item) => item.includes(query));

        setSearchResult(result);
      }
    }, 500); // Simulating a delay of 500 milliseconds
  }, []);

  const debounce = useCallback((func: (...args: any[]) => void, time = 300) => {
    let timeout: number;

    return (...args: any[]) => {
      const later = () => {
        timeout = 0;
        func(...args);
      };

      window.clearTimeout(timeout);

      timeout = window.setTimeout(later, time);
    };
  }, []);

  useEffect(() => {
    if (searchValue) {
      const debounceSearch = debounce(onSearch, 300); // Delay of 300 milliseconds
      debounceSearch(searchValue);
    }
  }, [debounce, onSearch, searchValue]);

  const closeSearch = () => {
    setIsSearchResultOpen(false);
    setSearchValue('');
    setSearchResult([]);
  };

  return (
    <div className="search-form-2">
      <div className="d-flex flex-column position-relative">
        {isSearchResultOpen ? (
          <i
            className="ti-close font-xss"
            style={{ cursor: 'pointer' }}
            onClick={closeSearch}
          />
        ) : (
          <i className="ti-search font-xss" />
        )}
        <input
          type="text"
          className="form-control text-grey-600 mb-0 bg-greylight theme-dark-bg border-0"
          placeholder="Search for someone"
          value={searchValue}
          onChange={(e) => {
            setSearchValue(e.target.value);
          }}
        />
      </div>
      {isSearchResultOpen && (
        <div
          className="position-absolute bg-lightgrey w-100"
          style={{
            height: 200,
            zIndex: 100,
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            borderEndEndRadius: 10,
            borderEndStartRadius: 10,
          }}
        >
          {searchResult.map((item) => {
            return (
              <div style={{
                cursor: 'pointer'
              }}>
                <figure className="avatar">
                  <img
                    src={'assets/images/user-100x100.png'}
                    alt="avater"
                    className="float-left p-1 rounded-circle w45"
                  />
                </figure>
                <p className="text-white fw-500 font-xsss text-grey-600 p-2">{item}</p>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default SearchBar;
