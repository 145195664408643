import React from 'react';
// import { profile } from '../../signals/profile';
// import CreatorNewsfeed from './CreatorNewsfeed';
import DefaultNewsfeed from './DefaultNewsfeed';

const Newsfeed = () => {
  // return profile.value.creatorId ? <CreatorNewsfeed /> : <DefaultNewsfeed />
  return <DefaultNewsfeed />
}

export default Newsfeed;
